import '@/main.scss'
import '@/assets/assets'
import vueUtil from '@/utils/vue-util'
import { createDownloadLink } from '@/utils/html-util'
import gtmUtil from '@/utils/gtm-util'
import navigationTracking from '@/utils/navigation-tracking'
import { textareaCounter } from '@/utils/forms'
import '@/utils/progress-bar'
import '@/utils/detectors'

import ModalContainer from '@/components/ModalContainer.vue'
import Slider from '@/components/Slider.vue'
import Video from '@/components/Video.vue'

import StoriesBlock from '@/components/blocks/StoriesBlock.vue'
import RecipesBlock from '@/components/blocks/RecipesBlock.vue'
import StepByStepBlock from '@/components/blocks/StepByStepBlock.vue'
import SearchOverview from '@/components/search/SearchOverview.vue'
import RecipeIngredients from '@/components/recipe/RecipeIngredients.vue'
import FavoritesOverview from '@/components/favorites/FavoritesOverview.vue'

import InvisibleRecaptchaElementBlock from '@/components/formElements/invisible-recaptcha-element-block.vue'
import PermissionElementBlock from '@/components/formElements/permission-element-block.vue'
import CompanyAutocompleteElementBlock from '@/components/formElements/company-autocomplete-element-block.vue'
import DairyFarmMapBlock from '@/components/blocks/DairyFarmMapBlock.vue'
import DealerMapBlock from '@/components/blocks/DealerMapBlock.vue'
import BBWButton from '@/components/BBWButton.vue'
import InfoPopup from '@/components/infoPopup/InfoPopup.vue'
import RedirectToolBuilder from '@/components/epiAdmin/RedirectToolBuilder.vue'
import ProductsExportTool from '@/components/epiAdmin/ProductsExportTool.vue'
import AddToFavorites from '@/components/AddToFavorites.vue'
import ProductImageModal from '@/components/ProductImageModal.vue'

import useHeader from '@/composition/useHeader'
import stickyMenu from '@/composition/stickyMenu'
import employeeList from '@/composition/employeeList'
import floatBuyButton from '@/composition/floatBuyButton'
import { checkHemInUrl } from '@/utils/hem'
import epiForms from '@/components/epiForms'
import { trackRecipeInstructionsImpression } from '@/composition/useRecipes'
import zoomer from '@/components/zoomer'

const components = {
  ModalContainer,
  Slider,
  Video,
  StoriesBlock,
  RecipesBlock,
  StepByStepBlock,
  InvisibleRecaptchaElementBlock,
  SearchOverview,
  FavoritesOverview,
  RecipeIngredients,
  PermissionElementBlock,
  CompanyAutocompleteElementBlock,
  DairyFarmMapBlock,
  DealerMapBlock,
  BBWButton,
  InfoPopup,
  RedirectToolBuilder,
  ProductsExportTool,
  AddToFavorites,
  ProductImageModal
}
vueUtil.mountComponents(components)

const { initStickyHeader, initMainNav } = useHeader()
initStickyHeader()
initMainNav()

const { initStickyMenu } = stickyMenu()
initStickyMenu()

const { initEmployeeListScroll } = employeeList()
initEmployeeListScroll()

const { initFloatBuyButton } = floatBuyButton()
initFloatBuyButton()
zoomer('.js-zoomer')

document.documentElement.classList.add('js-loaded')

window.downloadFile = (url, newWindow, fileName) => {
  createDownloadLink(url, newWindow, fileName)
}

gtmUtil.initHandler()
navigationTracking()
checkHemInUrl()
epiForms()
textareaCounter()
trackRecipeInstructionsImpression()
