<template>
  <div>
    <!-- Results list -->
    <component
      :is="gridComponent"
      :items="searchResult.items"
      :buy-button-label="translations.buyButtonLabel"
      :add-to-favorites-label="translations.addToFavoritesLabel"
      :remove-from-favorites-label="translations.removeFromFavoritesLabel"
    />

    <!-- Search more -->
    <div>
      <div
        v-if="!loading && !allItemsLoaded"
        class="u-flex u-flex-justify-center u-mt--s06"
      >
        <router-link
          :to="{ name: 'filters', query: getNextPageQuery() }"
          class="c-button"
          @click.prevent="searchMoreResults"
        >
          {{ translations.loadMoreButtonText }}
        </router-link>
      </div>
    </div>

    <!-- Empty state -->
    <div
      v-if="!loading && !searchResult.items.length"
      class="u-text-align--center u-p--s06"
    >
      <Icon
        :icon="iconSearch"
        :size="64"
        class="u-text-light-gray"
      />

      <div
        class="u-text-dark-gray u-mt--s04"
        v-html="translations.noResultHtml"
      />
    </div>

    <!-- Loading state -->
    <div
      v-if="loading"
      class="u-flex u-p--s08 u-flex-align-center u-flex-justify-center u-height-min-50vh"
    >
      <div class="c-spinner c-spinner--lg" />
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import Icon from '@/components/Icon.vue'
import ArticleGrid from '@/components/search/ArticleGrid.vue'
import RecipeGrid from '@/components/search/RecipeGrid.vue'
import ProductGrid from '@/components/search/ProductGrid.vue'

import iconSearch from '@/assets/img/icons/search.svg'
import searchFactory from '@/composition/search/searchFactory'
import searchQueryState from '@/composition/search/searchQuery'
import searchConfig from '@/composition/search/searchConfig'
import router from '@/composition/search/searchRouter'

export default {
  components: {
    Icon,
    ArticleGrid,
    RecipeGrid,
    ProductGrid
  },
  setup() {
    const { loading, allItemsLoaded } = searchFactory.useSearchResult()
    const { searchConfiguration } = searchConfig.searchConfigurationUse()
    const {
      hasExtendedFilters,
      searchType,
      searchResult,
      searchMoreResults
    } = searchQueryState.searchQueryUse()
    const translations = computed(() => searchConfiguration.translations)
    const gridComponent = computed(() => `${searchType.value}Grid`)

    const getNextPageQuery = () => {
      const { query } = router.currentRoute.value
      const page = query.page ? parseInt(query.page, 10) + 1 : 2

      return {
        ...query,
        page
      }
    }

    const totalCountText = computed(() => (translations.value
      ? translations.value.totalCountMessage.replace('{0}', searchResult.totalCount)
      : ''
    ))

    return {
      hasExtendedFilters,
      gridComponent,
      searchResult,
      loading,
      allItemsLoaded,
      iconSearch,
      totalCountText,
      translations,

      searchMoreResults,
      getNextPageQuery
    }
  }
}
</script>
