import debounce from '@/utils/debounce'

const cssClass = 'show'
const timeVisible = 2000
const debouncedFunc = debounce((callback) => {
  callback()
}, timeVisible)

const showFavoritesTooltip = (adding = true) => {
  const favoritesTooltip = document.getElementById('favorites-tooltip')
  favoritesTooltip.classList.add(cssClass)
  favoritesTooltip.classList[adding ? 'add' : 'remove']('adding')

  debouncedFunc(() => {
    favoritesTooltip.classList.remove(cssClass)
  })
}

// eslint-disable-next-line import/prefer-default-export
export { showFavoritesTooltip }
