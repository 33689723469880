<template>
  <form
    class="c-form u-width-text u-relative"
    @submit.prevent="onSubmit"
  >
    <div>
      <span class="u-mb--s04 h5 u-block">
        Сountry data
      </span>

      <div class="c-form-row">
        <label
          for="product-language"
          class="u-flex"
        >
          Language
        </label>
        <select
          id="product-language"
          v-model="language"
          class="u-width-200px"
          required
        >
          <option
            disabled
            hidden=""
            value=""
          >
            Choose language
          </option>
          <option
            v-for="(key, value) in model.languages"
            :key="key"
            :value="key"
            v-text="value"
          />
        </select>
      </div>
      <div
        v-if="noProducts"
        class="c-form-row"
      >
        Unfortunately no products are found for this country...
      </div>
      <div
        v-if="hasError"
        class="c-form-row"
      >
        Something went wrong...
      </div>
      <div class="c-form-row">
        <button
          class="c-button c-button--solid"
          type="submit"
          :disabled="!submitIsEnabled"
        >
          Get products
        </button>
      </div>
    </div>
    <div
      v-if="load"
      class="loader-overlay"
    >
      <span class="loader" />
    </div>
  </form>
</template>

<script>
import { ref, computed, watch } from 'vue'
import axios from 'axios'
import { createDownloadBlobLink } from '@/utils/html-util'

export default {
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  setup() {
    const language = ref('')
    const loading = ref(false)
    const noProducts = ref(false)
    const hasError = ref(false)
    const submitIsEnabled = computed(() => language.value && !loading.value && !noProducts.value)

    const getProducts = async () => {
      const { data, status } = await axios.get(`/api/productsexporttool/${language.value}`, { responseType: 'arraybuffer' })

      if (status === 204) {
        noProducts.value = true
      } else {
        createDownloadBlobLink(data, `arlapro-products-${language.value}.xlsx`)
      }
    }

    watch(language, () => {
      noProducts.value = false
      hasError.value = false
    })

    const onSubmit = async () => {
      loading.value = true
      try {
        hasError.value = false
        await getProducts()
      } catch (e) {
        hasError.value = true
      } finally {
        loading.value = false
      }
    }

    return {
      language,
      submitIsEnabled,
      noProducts,
      hasError,
      loading,

      onSubmit
    }
  }
}
</script>
