const fileUploadEvent = (container = document) => {
  const fileElements = container.querySelectorAll('input[type="file"]')
  fileElements.forEach((fileInputField) => {
    const fileContainer = fileInputField.parentNode
    const fileRemoveButton = fileContainer.querySelector('.js-clean-file')
    const cssClass = ['has-file', 'u-relative']
    fileInputField.addEventListener('change', () => {
      fileContainer.classList.add(...cssClass)
    })
    fileInputField.addEventListener('cancel', () => {
      fileContainer.classList.remove(...cssClass)
    })
    fileRemoveButton.addEventListener('click', () => {
      fileInputField.value = ''
      fileContainer.classList.remove(...cssClass)
    })
  })
}

export default () => {
  fileUploadEvent()
}
