<template>
  <a
    v-if="model.link"
    :href="model.link.url"
    class="c-recipe-card"
  >
    <div class="c-recipe-card__media">
      <Picture
        v-if="model.image"
        v-bind="mediaSizes"
        :src="model.image.src"
        class="c-recipe-card__media__image"
      />
    </div>
    <div class="c-card__content">
      <div class="c-card__text">
        <span class="e-preamble">
          {{ model.subtitle }}
        </span>
        <strong class="c-card__title">{{ model.title }}</strong>
        <a
          v-if="model.link && model.link.text"
          :href="model.link.url"
        >
          {{ model.link.text }}
        </a>
      </div>
    </div>
  </a>
</template>

<script>
import Picture from '@/components/Picture.vue'

export default {
  components: {
    Picture
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    mediaSizes: {
      type: Object,
      default: () => ({
        mobile: [767],
        tablet: [991],
        desktop: [1200],
        wide: [1920]
      })
    }
  }
}
</script>
