<template>
  <tr class="c-recipe-ingredients__table-row">
    <td
      class="c-recipe-ingredients__table-cell--lg u-text-capitalize-first-letter"
      :colspan="showBuyButton ? '1' : '2'"
    >
      <a
        v-if="model.product"
        :href="model.product.url"
      >
        <Picture
          v-if="model.product.image"
          v-bind="model.product.image"
          class="c-recipe-ingredients__thumbnail"
        />
        {{ ingredientName }}
      </a>
      <span v-else>{{ ingredientName }}</span>
    </td>
    <td v-if="showBuyButton">
      <BBWButton
        :model="model.product.buyButton"
        :placement="placement"
        :button-label="model.product.buyButtonLabel"
        class="c-recipe-ingredients__buy-button"
      >
        <span class="u-hide-on-screenonly">{{ model.product.buyButtonLabel }}</span>
      </BBWButton>
    </td>
    <td class="c-recipe-ingredients__table-cell--sm">
      {{ model.name.ingredientUnitName }}
    </td>
  </tr>
</template>

<script>
import { computed } from 'vue'
import Picture from '@/components/Picture.vue'
import BBWButton from '@/components/BBWButton.vue'

export default {
  components: {
    BBWButton,
    Picture
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const placement = 'Recipe Ingredients'
    const { product } = props.model
    const showBuyButton = product && product.buyButton
    return {
      placement,
      showBuyButton,
      ingredientName: computed(() => (
        props.model.secondaryUnit
          ? `${props.model.name.ingredientName} (${props.model.secondaryUnit})`
          : props.model.name.ingredientName))
    }
  }
}
</script>
