<template>
  <div
    :id="model.urlSegment"
    :class="cssClass"
    class="c-story-block u-mb--s10"
  >
    <Slider
      ref="slider"
      type="full"
      :tracking-type="trackingType"
      :track-navigation="false"
      @ready="onReady"
      @slide="onSlideChange"
    >
      <div
        v-for="(item, index) in model.items"
        :key="index"
        class="c-slide"
      >
        <div class="c-story-card">
          <div class="c-story-card__media">
            <Picture
              v-if="item.image"
              v-bind="item.image"
              class="c-story-card__media__image"
            />
          </div>
          <div class="c-story-card__content">
            <div class="c-story-card__text">
              <div class="c-story-card__text__inner">
                <span class="e-preamble">
                  {{ item.subtitle }} {{ progress }}
                </span>
                <h3 class="c-story-card__title">
                  {{ item.title }}
                </h3>
                <a
                  v-if="item.link"
                  :href="item.link.url"
                  class="e-link--arrow"
                  @click="onLinkClick"
                >{{ item.link.text }}</a>
              </div>
              <div class="c-story-card__controls">
                <button
                  data-controls="prev"
                  aria-controls="customize"
                  tabindex="-1"
                  :disabled="prevDisabled"
                  class="c-carousel__navigation-button c-carousel__navigation-button-prev"
                  @click="onPrev"
                >
                  <Icon
                    :icon="iconChevronRight"
                    :size="20"
                    class="c-carousel__button"
                  />
                </button>
                <button
                  data-controls="next"
                  aria-controls="customize"
                  tabindex="-1"
                  :disabled="nextDisabled"
                  class="c-carousel__navigation-button c-carousel__navigation-button-next"
                  @click="onNext"
                >
                  <Icon
                    :icon="iconChevronRight"
                    :size="20"
                    class="c-carousel__button"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Slider>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import Picture from '@/components/Picture.vue'
import Slider from '@/components/Slider.vue'
import Icon from '@/components/Icon.vue'
import iconChevronRight from '@/assets/img/icons/chevron--right.svg'
import gtmUtil from '@/utils/gtm-util'

export default {
  components: {
    Picture,
    Slider,
    Icon
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const trackingType = ref('stories block')
    const currentSlide = ref(null)
    const currentTitle = ref(null)
    const totalSlides = ref(null)
    const progress = computed(() => `${currentSlide.value}/${totalSlides.value}`)
    const prevDisabled = ref(true)
    const nextDisabled = ref(true)
    const slider = ref(null)

    const onPrev = () => {
      slider.value.prev()
    }

    const onNext = () => {
      slider.value.next()
    }

    const updateNav = (info) => {
      currentSlide.value = info.displayIndex
      prevDisabled.value = !info.prevEnabled
      nextDisabled.value = !info.nextEnabled
      currentTitle.value = props.model.items[info.index].title

      if (info.direction) {
        gtmUtil.sliderScroll(trackingType.value, currentTitle.value, info.direction)
      }
    }

    const onLinkClick = (e) => {
      gtmUtil.trackLink(e, e.target, {
        event: 'sliderItemClick',
        sliderType: trackingType.value,
        sliderTitle: currentTitle.value
      })
    }

    const cssClass = computed(() => [
      props.model.theme ? `u-theme--${props.model.theme}` : '',
      { 'is-single': props.model.items.length === 1 }
    ])

    return {
      trackingType,
      iconChevronRight,
      slider,
      onPrev,
      onNext,
      progress,
      prevDisabled,
      nextDisabled,
      cssClass,
      onReady: (e) => {
        totalSlides.value = e.slideCount
        updateNav(e)
      },
      onSlideChange: updateNav,
      onLinkClick
    }
  }
}
</script>
