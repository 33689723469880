<template>
  <div
    :class="{ 'expanded' : isExpanded || isAnimating }"
    class="item-filter"
  >
    <strong
      class="item-filter-title h6"
      role="button"
      tabindex="0"
      @click="toggle"
      @keyup.enter="toggle"
    >
      {{ category.name }}
      <CI
        :icon="arrowIcon"
      />
    </strong>

    <div
      ref="content"
      :style="showStyles ? contentStyle : ''"
      class="item-filter__list-wrap"
      :class="{'is-scrollable': withScrollbar}"
    >
      <SearchFiltersList :model="category" />
    </div>
  </div>
</template>

<script>
import SearchFiltersList from '@/components/search/SearchFiltersList.vue'
import arrowIcon from '@/assets/img/icons/arrow-down--thick.svg'
import CI from '@/components/CI.vue'

import expandCollapse from '@/mixins/expand-collapse'

export default {
  components: {
    SearchFiltersList,
    CI
  },
  mixins: [
    expandCollapse
  ],
  props: {
    category: {
      type: Object,
      required: true
    },
    minHeight: {
      type: Number,
      default: 0
    },
    isExpandedByDefault: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    return {
      arrowIcon
    }
  }
}
</script>
