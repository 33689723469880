<template>
  <div
    ref="recipeIngredients"
    class="c-recipe-ingredients"
  >
    <h2>{{ model.ingredientsHeading }}</h2>

    <!-- Portion selector -->
    <template v-if="portions">
      <span
        v-if="!model.ingredients.amountIsScalable"
        class="c-recipe-ingredients__portion--fixed"
      >
        {{ portions }} {{ amountText }}
      </span>

      <div
        v-else
        class="c-recipe-ingredients__portions"
      >
        <button
          class="c-recipe-ingredients__portions-button"
          data-gtm-trigger="click"
          :aria-label="model.ingredientsScaleDownLabel"
          @click="performStep('down')"
        >
          <Icon
            :icon="iconSubtract"
            :size="24"
          />
        </button>
        <div class="c-recipe-ingredients__portions-label">
          {{ portions }} {{ amountText }}
        </div>
        <button
          class="c-recipe-ingredients__portions-button"
          data-gtm-trigger="click"
          :aria-label="model.ingredientsScaleUpLabel"
          @click="performStep('up')"
        >
          <Icon
            :icon="iconAdd"
            :size="24"
          />
        </button>
      </div>
    </template>

    <!-- Ingredient list -->
    <table class="c-recipe-ingredients__table">
      <recipe-ingredient-group
        v-for="ingredientGroup in scaledIngredientsGroups"
        :key="ingredientGroup.index"
        :model="ingredientGroup"
      />
    </table>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue'
import RecipeIngredientGroup from '@/components/recipe/RecipeIngredientGroup.vue'
import Icon from '@/components/Icon.vue'
import iconAdd from '@/assets/img/icons/add.svg'
import iconSubtract from '@/assets/img/icons/subtract.svg'
import gtmUtil from '@/utils/gtm-util'
import ingredientScaleHelper from '@/composition/recipeIngredientsScaleHelper'
import { trackRecipeImpression } from '@/composition/useRecipes'

export default {
  components: {
    Icon,
    RecipeIngredientGroup
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const recipeIngredients = ref()
    const { setPortions, state } = ingredientScaleHelper(props.model)

    const minValue = 1
    const maxValue = state.basePortions * 10
    const stepValue = 1

    function performStep(action) {
      let newValue
      if (action === 'up') {
        newValue = Math.floor((state.portions + stepValue) / stepValue) * stepValue
      } else {
        newValue = Math.ceil((state.portions - stepValue) / stepValue) * stepValue
      }

      if (action === 'up' && newValue <= maxValue) {
        setPortions(Math.floor((state.portions + stepValue) / stepValue) * stepValue)
        gtmUtil.trackRecipeScalingUp(props.model.heading, state.portions, state.amountText)
      } else if (action === 'down' && newValue >= minValue) {
        setPortions(Math.ceil((state.portions - stepValue) / stepValue) * stepValue)
        gtmUtil.trackRecipeScalingDown(props.model.heading, state.portions, state.amountText)
      }
    }

    onMounted(() => {
      const element = recipeIngredients.value
      const trackingFunction = gtmUtil.trackRecipeIngredients10sec
      const recipeName = props.model.heading

      trackRecipeImpression({
        element,
        trackingFunction,
        recipeName
      })
    })

    return {
      minValue,
      maxValue,
      stepValue,
      portions: computed(() => state.portions),
      amountText: computed(() => state.amountText),
      scaledIngredientsGroups: computed(() => state.ingredientGroups),
      iconAdd,
      iconSubtract,
      recipeIngredients,

      performStep
    }
  }
}
</script>
