<template>
  <article class="c-article-list__item">
    <a
      v-if="model.uid"
      :href="model.url"
      class="c-article-list__item__link"
    >
      <Picture
        v-if="model.image"
        v-bind="model.image"
        class="c-article-list__item__image"
      />

      <div class="c-card__content">
        <div class="c-card__text">
          <span
            v-if="model.subHeading"
            class="e-preamble"
          >
            {{ model.subHeading }}
          </span>
          <strong class="c-card__title">{{ model.heading }}</strong>
        </div>
      </div>
    </a>
  </article>
</template>

<script>
import Picture from '@/components/Picture.vue'

export default {
  components: {
    Picture
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  }
}
</script>
