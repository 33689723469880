<template>
  <div class="c-card-grid">
    <ProductCard
      v-for="(item, index) in items"
      :key="index"
      :model="item"
      :buy-button-label="buyButtonLabel"
      :add-to-favorites-label="addToFavoritesLabel"
      :remove-from-favorites-label="removeFromFavoritesLabel"
      placement="product card grid"
    />
  </div>
</template>

<script>
import ProductCard from '@/components/ProductCard.vue'

export default {
  components: {
    ProductCard
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    buyButtonLabel: {
      type: String,
      required: true
    },
    addToFavoritesLabel: {
      type: String,
      required: true
    },
    removeFromFavoritesLabel: {
      type: String,
      required: true
    }
  }
}
</script>
