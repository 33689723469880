import gtmUtil from '@/utils/gtm-util'

const header = document.querySelector('.js-header')
const stickyMenu = document.querySelector('.c-sticky-menu')

const pinnedClass = 'is--pinned'
const fixedClass = 'is--fixed'

let isFixed = false
let isPinned = false
let lastPosition = 0

const setHeaderFixed = (value) => {
  isFixed = value
  header.classList[isFixed ? 'add' : 'remove'](fixedClass)
}

const setHeaderPinned = (value) => {
  isPinned = value

  header.classList[isPinned ? 'add' : 'remove'](pinnedClass)
  if (stickyMenu) {
    stickyMenu.classList[isPinned ? 'add' : 'remove'](pinnedClass)
  }
}

export default () => {
  const initStickyHeader = () => {
    if (!header) {
      return
    }
    const handleScroll = () => {
      let scrolledUp
      const headerHeight = header.clientHeight
      const headerRect = header.getBoundingClientRect()

      if (lastPosition < window.scrollY) {
        scrolledUp = false
      }

      if (lastPosition > window.scrollY) {
        scrolledUp = true
      }

      setHeaderFixed(headerRect.top < 0)
      setHeaderPinned(scrolledUp || headerHeight + headerRect.top > 0)
      lastPosition = window.scrollY
    }

    handleScroll()
    window.addEventListener('scroll', handleScroll)
  }

  const initMainNav = () => {
    if (!header) {
      return
    }

    const opener = header.querySelector('.js-nav-opener')
    const openedClass = 'nav-open'
    let isOpened = false

    opener.addEventListener('click', () => {
      isOpened = !isOpened
      document.body.classList[isOpened ? 'add' : 'remove'](openedClass)

      if (isOpened) {
        gtmUtil.expandMenu()
      }
    })
  }

  return {
    initStickyHeader,
    initMainNav
  }
}

export { setHeaderPinned }
