import { showFavoritesTooltip } from '@/composition/favorites/favoritesTooltip'

const languagePrefix = window.language ? `${window.language}:` : ''
const STORAGE_NAMES = {
  product: `${languagePrefix}favorite-products`
}

function getItemIds(type = 'product') {
  const items = localStorage.getItem(STORAGE_NAMES[type])
  return items ? items.split(',') : []
}

function isFavorite(id, type = 'product') {
  const storage = localStorage.getItem(STORAGE_NAMES[type]) || ''
  return storage.indexOf(id) !== -1
}

function addToFavorites(productId, type = 'product') {
  let storage = localStorage.getItem(STORAGE_NAMES[type]) || ''
  storage = storage ? `${productId},${storage}` : `${productId}`
  localStorage.setItem(STORAGE_NAMES[type], storage)
  showFavoritesTooltip(true)

  return storage
}

function removeBulkFromFavorites(ids, type = 'product') {
  let storage = localStorage.getItem(STORAGE_NAMES[type]) || ''
  storage = storage.split(',').filter((id) => ids.indexOf(id) < 0).join(',')
  showFavoritesTooltip(false)

  if (storage === '') {
    localStorage.removeItem(STORAGE_NAMES[type])
  } else {
    localStorage.setItem(STORAGE_NAMES[type], storage)
  }
}

function removeFromFavorites(id, type = 'product') {
  removeBulkFromFavorites([id], type)
}

export {
  getItemIds,
  addToFavorites,
  isFavorite,
  removeFromFavorites,
  removeBulkFromFavorites
}
