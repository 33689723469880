<template>
  <div>
    <span
      :class="openerClass"
      role="button"
      tabindex="0"
      @click="onOpenModal"
      @keydown.enter="onOpenModal"
    >
      {{ openerTitle }}
    </span>
    <Modal
      ref="modal"
      :close-text="closeText"
      type="image"
      @onCloseClick="onCloseModal"
    >
      <div
        v-if="slotContent"
        v-html="slotContent"
      />
      <slot v-else />
    </Modal>
  </div>
</template>

<script>
import { ref } from 'vue'
import Modal from '@/components/Modal.vue'
import gtmUtil from '@/utils/gtm-util'

export default {
  components: {
    Modal
  },
  props: {
    slotContent: {
      type: String,
      default: ''
    },
    openerTitle: {
      type: String,
      required: true
    },
    openerClass: {
      type: String,
      default: ''
    },
    closeText: {
      type: String,
      default: 'close'
    },
    placement: {
      type: String,
      default: 'buy product block'
    }
  },
  setup(props) {
    const modal = ref(null)
    const { placement } = props

    const onOpenModal = () => {
      modal.value.onOpenModal()
      gtmUtil.productImagePopupAction({
        placement,
        action: 'open'
      })
    }

    const onCloseModal = () => {
      gtmUtil.productImagePopupAction({
        placement,
        action: 'close'
      })
    }

    return {
      modal,

      onOpenModal,
      onCloseModal
    }
  }
}
</script>
