<template>
  <a
    v-if="model.uid"
    :href="model.url"
    class="c-recipe-list__item c-recipe-list__item__link"
  >
    <Picture
      v-bind="model.image"
      class="c-recipe-list__item__image"
    />
    <div class="c-card__content">
      <div class="c-card__text">
        <span
          v-if="model.subHeading"
          class="e-preamble"
        >
          {{ model.subHeading }}
        </span>
        <strong class="c-card__title c-recipe-list__item__title">
          {{ model.heading }}
        </strong>
      </div>
    </div>
  </a>
</template>

<script>
import Picture from '@/components/Picture.vue'

export default {
  components: {
    Picture
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  }
}
</script>
