if (!CSS.supports('animation-timeline: scroll()')) {
  const progressBar = document.getElementById('progress')
  if (progressBar) {
    const progressLine = progressBar.children[0]
    const checkProgress = () => {
      const scrollPosition = document.documentElement.scrollTop
      const scrollDistance = document.documentElement.scrollHeight - document.documentElement.clientHeight
      const progress = (scrollPosition / scrollDistance)
      progressLine.style.transform = `scaleX(${progress})`

      if (progress < 0.01) {
        progressBar.style.height = '0px'
      } else {
        progressBar.removeAttribute('style')
      }
    }
    document.addEventListener('scroll', checkProgress)
    checkProgress()
  }
}
