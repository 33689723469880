<template>
  <div
    :class="{ 'is-portrait': currentisPortraitMode }"
    class="c-video"
  >
    <div
      v-if="isYoutube"
      class="c-video__iframe-container"
    >
      <iframe
        :src="currentSrc"
        :title="heading"
        frameborder="0"
        allowfullscreen
      />
    </div>
    <template v-else>
      <video-js
        ref="playerRef"
        :aria-label="description"
        class="video-js"
      />
      <img
        v-if="currentPoster"
        :src="currentPoster"
        :alt="heading"
      >

      <button
        v-if="showPlayBtnOverlay"
        class="c-video-play-button"
        :aria-label="playButtonLabel"
        @click="onHandleClick"
      >
        <span class="u-m--a">
          <CI
            :icon="IconPlay"
            size="32"
            fill="none"
          />
        </span>
      </button>
    </template>
  </div>
</template>

<script>
import 'video.js/dist/video-js.css'
import {
  onBeforeUnmount, onMounted, ref, computed
} from 'vue'
import videojs from 'video.js'
import CI from '@/components/CI.vue'
import { IconPlay } from '@/utils/icons'
import gtmUtil from '@/utils/gtm-util'

const defaultModel = {
  src: '',
  mobileSrc: '',
  poster: '',
  mobilePoster: '',
  isPortraitMode: false,
  mobileIsPortraitMode: false,
  playsinline: false,
  autoplay: false,
  loop: false,
  muted: false,
  controls: true,
  showBtnPlay: false,
  type: 'azure',
  heading: '',
  playButtonLabel: '',
  description: ''
}

export default {
  components: {
    CI
  },
  props: {
    model: {
      type: Object,
      required: true,
      default: defaultModel
    },
    type: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const {
      src,
      mobileSrc,
      poster,
      mobilePoster,
      isPortraitMode,
      mobileIsPortraitMode,
      playsinline,
      autoplay,
      loop,
      muted,
      controls,
      showBtnPlay,
      type,
      heading,
      description,
      playButtonLabel
    } = props.model

    let player
    const playerRef = ref()
    const trackingDuration = []
    const availableTrackingDurations = [90, 75, 50, 25, 10]
    const showPlayBtnOverlay = ref(showBtnPlay)
    const showPoster = ref(showBtnPlay && !autoplay)
    const isPlaying = ref(false)
    const isYoutube = computed(() => type === 'youtube')
    const currentSrc = ref(src)
    const isMobile = window.matchMedia('(max-width: 992px)').matches
    if (mobileSrc && isMobile) {
      currentSrc.value = mobileSrc
    }
    const currentPoster = ref(poster)
    if (mobilePoster && isMobile) {
      currentPoster.value = mobilePoster
    }
    const currentisPortraitMode = ref(isMobile ? mobileIsPortraitMode : isPortraitMode)
    const videoOptions = computed(() => ({
      playsinline,
      autoplay,
      loop,
      muted,
      controls,
      liveui: controls,
      sources: [
        {
          src: currentSrc.value,
          type: 'application/x-mpegURL'
        }
      ]
    }))

    const onHandleClick = () => {
      if (showPlayBtnOverlay.value) {
        player.play()
        showPlayBtnOverlay.value = false
        gtmUtil.videoStart(heading)
      }
    }

    const getPercent = (tempPercent, percentValue) => {
      if (tempPercent > percentValue && !trackingDuration.includes(percentValue)) {
        return percentValue
      }
      return 0
    }

    onMounted(() => {
      if (!isYoutube.value) {
        player = videojs(playerRef.value, videoOptions.value)

        if (!autoplay) {
          player.on('ended', () => {
            gtmUtil.videoComplete(heading)
          })
          player.on('play', () => {
            showPoster.value = false
          })
          player.on('timeupdate', () => {
            const tempPercent = Math.trunc((player.currentTime() * 100) / player.duration())
            let percent = 0
            for (let i = 0; i < availableTrackingDurations.length; i += 1) {
              percent = getPercent(tempPercent, availableTrackingDurations[i])
              if (percent !== 0) {
                break
              }
            }

            if (percent !== 0 && !trackingDuration.includes(percent)
              && availableTrackingDurations.includes(percent)) {
              gtmUtil.videoProgress(heading, percent)
              trackingDuration.push(percent)
            }
          })
        }
      }
    })

    onBeforeUnmount(() => {
      if (player) {
        player.dispose()
      }
    })

    return {
      isPlaying,
      playerRef,
      showPlayBtnOverlay,
      isYoutube,
      IconPlay,
      currentSrc,
      currentPoster,
      currentisPortraitMode,
      heading,
      description,
      playButtonLabel,
      onHandleClick
    }
  }
}
</script>
