<template>
  <span
    :class="{ 'is-added': isAdded }"
    class="c-add-to-favorites"
  >
    <span
      :class="{ 'c-button': isButton }"
      role="button"
      tabindex="0"
      :aria-label="buttonText"
      @click.stop.prevent="toggleFavorites"
      @keyup.enter="toggleFavorites"
    >
      <span class="c-icon-holder">
        <CI
          :icon="IconBookmark"
          class="c-base-icon"
          size="16"
        />
      </span>
      <span
        v-if="buttonText"
        :class="{ 'u-hide-on-screenonly': !showText }"
      >
        {{ buttonText }}
      </span>
    </span>
  </span>
</template>

<script>
import { ref, computed } from 'vue'
import {
  isFavorite,
  addToFavorites,
  removeFromFavorites
} from '@/composition/favorites/favoritesLSRepository'
import { IconBookmark } from '@/utils/icons'
import gtmUtil from '@/utils/gtm-util'
import CI from '@/components/CI.vue'
import { setHeaderPinned } from '@/composition/useHeader'

export default {
  components: {
    CI
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    addText: {
      type: String,
      default: ''
    },
    removeText: {
      type: String,
      default: ''
    },
    isButton: {
      type: Boolean,
      default: false
    },
    showText: {
      type: Boolean,
      default: false
    },
    placement: {
      type: String,
      required: true
    }
  },
  setup(props) {
    // TODO: Make sure we save ean for products as id's
    const {
      type,
      placement,
      model: {
        ean: id,
        heading,
        name
      }
    } = props
    const productName = name || heading
    const isAdded = ref(isFavorite(id, type))

    const toggleFavorites = () => {
      setHeaderPinned(true)
      if (isAdded.value) {
        removeFromFavorites(id)
        gtmUtil.removeFromFavorites({
          id, productName, type, placement
        })

        isAdded.value = !isAdded.value
      } else {
        addToFavorites([id])
        gtmUtil.addToFavorites({
          id, productName, type, placement
        })

        isAdded.value = !isAdded.value
      }
    }

    const buttonText = computed(() => (isAdded.value ? props.removeText : props.addText))

    return {
      isAdded,
      buttonText,
      IconBookmark,

      toggleFavorites
    }
  }
}
</script>
