const zoom = (e) => {
  const zoomer = e.currentTarget
  const zoomedImage = zoomer.querySelector('.js-zoomer-image')
  const offsetX = e.offsetX || e.touches[0].pageX
  const offsetY = e.offsetY || e.touches[0].pageY
  const x = (offsetX / zoomer.offsetWidth) * 100
  const y = (offsetY / zoomer.offsetHeight) * 100
  zoomedImage.style.backgroundPosition = `${x}% ${y}%`
}

export default (selectors) => {
  document.querySelectorAll(selectors).forEach((element) => {
    element.addEventListener('mousemove', zoom)
  })
}
